import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { RemoteReportProvider } from '~/presentation/hooks/remoteReport';
import { GetDiagnosisContextProvider } from '~/presentation/hooks/remoteReport/loadData';
import { UFProvider } from '~/presentation/hooks/useUF';
import { RemoteReportView } from '~/presentation/pages/RemoteReport';
import {
  EditDiagnosisReport,
  schemaEditDiagnosisReport,
} from '~/validation/validators/editDiagnosisReport';
import { makeReduxGetAllConsultant } from '../../usecases/consultant/GetAllConsultant';
import { makeReduxProfessionList } from '../../usecases/profession';
import { makeReduxGetAllProfessionals } from '../../usecases/professional/GetAllProfessionalFactory';

export const ViewDiagnosticFactory: React.FC = () => {
  const form = useForm<EditDiagnosisReport>({
    resolver: zodResolver(schemaEditDiagnosisReport),
    mode: 'onBlur',
    defaultValues: {
      consultantInfo: '',
      consultantExternal: {
        id: null,
      },
    },
  });

  const { orgId, orgUnitId, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const loadData = useCallback(() => {
    makeReduxGetAllProfessionals().getAll({
      filter: {
        org: orgId,
      },
    });
    makeReduxGetAllConsultant().getAll({
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
      },
    });
    makeReduxProfessionList().post({});
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <FormProvider {...form}>
      <UFProvider>
        <GetDiagnosisContextProvider>
          <RemoteReportProvider>
            <RemoteReportView />
          </RemoteReportProvider>
        </GetDiagnosisContextProvider>
      </UFProvider>
    </FormProvider>
  );
};
