import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { NewShedule } from '~/presentation/roles/professional/pages';
import { makeReduxGetAllConsultant } from '../../usecases/consultant/GetAllConsultant';
import { makeRemoteGetHealthPlan } from '../../usecases/healthPlan/GetHealthPlan';
import { makeReduxGetAllOrganizer } from '../../usecases/organizer/GetAllOrganizer';
import { makeReduxProfessionList } from '../../usecases/profession/ProfessionListFactory';
import { makeReduxGetAllProfessionals } from '../../usecases/professional/GetAllProfessionalFactory';

export const MakeNewAppointmentPage: React.FC = () => {
  const { selected } = useSelector((state: iStore) => state.professions);
  const { orgId, orgUnitId, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  useEffect(() => {
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
      filter: { hasProfessional: true, enabled: true, profession: selected },
    });

    makeReduxProfessionList().post({
      // filter: { // TODO: Uncomment this line when the backend is ready
      //   enabled: true,
      //   hasProfessional: true,
      // },
    });

    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    makeReduxGetAllOrganizer().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgId, orgUnitId, selected]);

  return <NewShedule />;
};
