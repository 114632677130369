/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';

import { translator } from '~/presentation/components/i18n';

import { ContentComponents, Nav, NavContent } from './styles/StyledService';

import { ConsultationData } from '~/presentation/components/consultationData';
import { NavigationBar } from '~/presentation/components/navigationBar';
import { Notes } from '~/presentation/components/notes';
import {
  AppointmentDetail,
  iClinicalDocs,
  iSupportDocs,
} from '../../pages/appointment/interface';
import ClinicalDocuments from './ClinicalDocuments';
import Media from './MediaView';
import SupportingDocuments from './SupportingDocuments';
import { Link } from '~/presentation/components/link';

interface ownProps {
  clinicalDocs: iClinicalDocs[];
  supportingDocs: iSupportDocs[];
  openFunction: Function;
  infos: AppointmentDetail;
}

const AppointementDocs: React.FC<ownProps> = ({
  clinicalDocs,
  supportingDocs,
  openFunction,
  infos,
}) => {
  const labels: string[] = [
    translator('Dados da consulta'),
    translator('Documentos clínicos'),
    translator('Documentos de apoio'),
    translator('Anotações'),
    translator('Gravação'),
    // translator('Chat'),
  ];

  const [select, setSelect] = useState<string>(labels[0]);

  function handleSelect(key: string) {
    setSelect(key);
  }

  function renderContent() {
    switch (labels.indexOf(select)) {
      case 0:
        return <ConsultationData infos={infos} />;
      case 1:
        return infos.session?.status !== 'ENDED' ? (
          <Link />
        ) : (
          <ClinicalDocuments docs={clinicalDocs} openFunction={openFunction} />
        );
      case 2:
        return <SupportingDocuments supportingDocs={supportingDocs} />;
      case 3:
        return <Notes />;
      case 4:
        return <Media />;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (infos.session?.status !== 'ENDED')
      labels.unshift(translator('Links e Convidados'));
  }, [infos]);

  return (
    <NavContent>
      <Nav>
        <NavigationBar
          active={select}
          labels={
            infos.session?.appointmentType?.descr === 'Interconsulta' &&
            infos?.session?.status !== 'ENDED'
              ? labels.slice(0, 2)
              : labels
          }
          clickLabel={handleSelect}
        />
      </Nav>
      <ContentComponents>{renderContent()}</ContentComponents>
    </NavContent>
  );
};

export default AppointementDocs;
