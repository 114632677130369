import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ClinicalPage } from '~/presentation/roles/professional/pages';

import { makeReduxGetAllProfessionals } from '../../usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllConsultant } from '../../usecases/consultant/GetAllConsultant';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllOrganizer } from '../../usecases/organizer/GetAllOrganizer';

export const MakeCreateSimplePrescriptionPage: React.FC = () => {
  const { orgId, orgUnitId, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  useEffect(() => {
    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    makeReduxGetAllOrganizer().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  });

  return <ClinicalPage />;
};
