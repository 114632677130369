import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  AppointmentDataIcon,
  AppointmentDataSelectedIcon,
  ClinicalDocIcon,
  ClinicalDocSelectedIcon,
  ESusIcon,
  NotesIcon,
  NotesSelectedIcon,
  SupportDocIcon,
  SupportDocSelectedIcon,
} from '~/presentation/base/icons';

import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { iStore } from '~/domain/interfaces/models';
import {
  schema,
  schemaEditConsult,
} from '~/validation/validators/appointment/UpdateAppointmentValidator';
import { makeRemoteGetAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { GetAppointmentType } from '~/domain/usecases/appointmentType/remote';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { makeRemoteGetDetailsInstantAppointment } from '~/main/factories/usecases/appointment/GetDetailsInstantAppointmentFactory';
import { AppointmentsInstantaneousToById } from '~/domain/dtos/appointment/AppointmentsInstantaneousToById';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { iUpdateAppointment } from '../editConsult/interface';
import { InterconsultData } from './interconsultData/index';
import { SupportDocs } from './supportDocs';
import { ClinicalDocs } from './clinicalDocs';
import { Notes } from './notes';
import {
  Container,
  Item,
  ItemsContainer,
  V4HLogo,
} from './styles/sidebarStyles';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';

enum SidebarOptions {
  NONE = 0,
  NOTES = 1,
  CLINICALDOCS = 2,
  SUPPORTDOCS = 3,
  INTER_INFO = 4,
}

export interface ParamsState {
  appointmentId: string;
  guestData?: iGuestData;
}

const Sidebar: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<number>(
    SidebarOptions.NONE,
  );
  const [appointment, setAppointment] = useState<GetAppointmentById.Model>(
    {} as GetAppointmentById.Model,
  );
  const [types, setTypes] = useState<GetAppointmentType.Model | undefined>(
    undefined,
  );

  const { orgId, orgUnitId, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { appointmentId, guestData } = useLocation<ParamsState>().state;
  const [displayESus, setDisplayESus] = useState<boolean>(false);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const openPopup = useCallback(() => {
    window.open(
      'https://pecestadual.codata.pb.gov.br',
      'popup',
      'width=600,height=600',
    );
  }, []);

  const formData = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaEditConsult),
    defaultValues: {},
  });

  const appointmentType = useMemo(() => {
    if (!types || !appointment) return undefined;

    return types?.find(item => item.id === appointment?.appointment?.type);
  }, [appointment, types]);

  const appointmentIdFormatted = useMemo(() => {
    if (appointmentId) return appointmentId;
    if (guestData?.appointment.id) return String(guestData?.appointment.id);

    return undefined;
  }, [appointmentId, guestData?.appointment.id]);

  const renderContent = useMemo(() => {
    switch (selectedOption) {
      case SidebarOptions.NOTES:
        return (
          <Notes
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
            appointment={appointment}
          />
        );
      case SidebarOptions.CLINICALDOCS:
        return (
          <ClinicalDocs
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      case SidebarOptions.SUPPORTDOCS:
        return (
          <SupportDocs
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      case SidebarOptions.INTER_INFO:
        return (
          <InterconsultData
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
            appointment={
              loading ? ({} as GetAppointmentById.Model) : appointment
            }
            loading={loading}
            handleUpdate={() => setForceUpdate(true)}
          />
        );
      default:
        return <div />;
    }
  }, [selectedOption, appointment, loading]);

  useEffect(() => {
    const code = guestData?.guest?.identification?.code;

    const isAppointmentEmpty = Object.keys(appointment).length === 0;
    const refetch = isAppointmentEmpty || forceUpdate;

    if (!loading && code && refetch) {
      setLoading(true);
      makeRemoteGetDetailsInstantAppointment()
        .getDetailsInstantAppointment({
          code,
        })
        .then(res => {
          const formattedRes = AppointmentsInstantaneousToById(res);
          setAppointment(formattedRes);
        })
        .catch(err => console.log('err: ', err))
        .finally(() => {
          setLoading(false);
          setForceUpdate(false);
        });
    }

    if (!loading && !code && appointmentIdFormatted && refetch) {
      setLoading(true);
      makeRemoteGetAppointmentById()
        .getAppointmentById({ id: Number(appointmentIdFormatted) })
        .then(res => {
          setAppointment(res);
        })
        .catch(err => console.log('err: ', err))
        .finally(() => {
          setLoading(false);
          setForceUpdate(false);
        });
    }
  }, [
    forceUpdate,
    selectedOption,
    appointmentIdFormatted,
    guestData?.guest?.identification?.code,
    appointment,
  ]);

  useEffect(() => {
    if (!appointment) return;

    if (
      appointment?.otherProfessionals?.find(
        item => item.code === guestData?.guest?.identification?.code,
      )
    )
      setDisplayESus(true);
  }, [appointment, guestData?.guest?.identification?.code]);

  useEffect(() => {
    makeRemoteGetAppointmentType()
      .list({
        client: 'SAUDEMEET',
      })
      .then(res => {
        setTypes(res);
      })
      .catch(err => {
        console.log('err: ', err);
      });

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });

    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgId, orgUnitId]);

  return (
    <>
      <Container>
        <ItemsContainer>
          {role === 'PRO' && (
            <Item
              selected={selectedOption === SidebarOptions.NOTES}
              onClick={() => {
                if (!loading) setSelectedOption(SidebarOptions.NOTES);
              }}
            >
              {selectedOption === SidebarOptions.NOTES ? (
                <NotesSelectedIcon />
              ) : (
                <NotesIcon />
              )}
            </Item>
          )}
          <Item
            selected={selectedOption === SidebarOptions.CLINICALDOCS}
            onClick={() => {
              if (!loading) setSelectedOption(SidebarOptions.CLINICALDOCS);
            }}
          >
            {selectedOption === SidebarOptions.CLINICALDOCS ? (
              <ClinicalDocSelectedIcon />
            ) : (
              <ClinicalDocIcon />
            )}
          </Item>
          <Item
            selected={selectedOption === SidebarOptions.SUPPORTDOCS}
            onClick={() => {
              if (!loading) setSelectedOption(SidebarOptions.SUPPORTDOCS);
            }}
          >
            {selectedOption === SidebarOptions.SUPPORTDOCS ? (
              <SupportDocSelectedIcon />
            ) : (
              <SupportDocIcon />
            )}
          </Item>

          <Item
            selected={selectedOption === SidebarOptions.INTER_INFO}
            onClick={() => {
              if (!loading) setSelectedOption(SidebarOptions.INTER_INFO);
            }}
          >
            {selectedOption === SidebarOptions.INTER_INFO ? (
              <AppointmentDataSelectedIcon />
            ) : (
              <AppointmentDataIcon />
            )}
          </Item>

          {(guestData?.guest?.identification?.code?.length
            ? displayESus
            : role === 'PRO') && (
            <Item onClick={openPopup}>
              <ESusIcon />
            </Item>
          )}
        </ItemsContainer>
        <V4HLogo />
      </Container>
      {renderContent}
    </>
  );
};

export default Sidebar;
