import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { iStore } from '~/domain/interfaces/models';
import { Input, Select, Button } from '~/presentation/components/UI';
import {
  ContainerButtons,
  ContainerForm,
  FormItem,
  Container,
  Options,
} from './styles/StyledFilterPatient';
import { translator } from '~/presentation/components/i18n';

import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';

const FilterPatient: React.FC = () => {
  const [consultant, setConsultant] = useState('');
  const [identificateCpf, setIdentificateCpf] = useState<string>('');
  const [status, setStatus] = useState<string>('DEFAULT');
  const [code, setCode] = useState('');

  const { auth } = useSelector((store: iStore) => store);

  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const handleFilter = () => {
    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
        name: consultant,
        cpf: identificateCpf,
        status: status === 'DEFAULT' ? undefined : status,
        id: Number(code) ?? undefined,
      },
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    setConsultant('');
    setIdentificateCpf('');
    setStatus('DEFAULT');
    setCode('');

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: role === 'ORG' ? orgUnitId : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
        userId: role === 'CON' ? consultantId : undefined,
      },
    });
  };

  return (
    <Container>
      <ContainerForm>
        <FormItem>
          <p>{`${translator('Paciente')}`}</p>
          <Input
            id="input_patient"
            value={consultant}
            onChange={e => setConsultant(e.target.value)}
            placeholder={`${translator('Digite o nome do paciente')}`}
            className="hover-input"
          />
        </FormItem>
        <FormItem>
          <p>Código</p>
          <Input
            id="input_code"
            value={code}
            name="code"
            onChange={e => setCode(e.target.value.replace(/\D/, ''))}
            placeholder={`${translator('Digite o código do paciente')}`}
            className="hover-input"
          />
        </FormItem>
        {/* <FormItem>
          <p>Data inclusão</p>
          <InputKeyBoardDate
            state={new Date()}
            setState={() => ''}
            placeholder="Data"<Options value="Online">Online</Options>
          />
        </FormItem> */}
        <FormItem>
          <p>{`${translator('CPF')}`}</p>
          <InputMask
            mask="999.999.999-99"
            onChange={e =>
              setIdentificateCpf(e.target.value.replace(/[^\d]+/g, '').trim())
            }
            value={identificateCpf}
          >
            <Input
              id="input_cpf"
              name="cpf"
              placeholder={`${translator('Digite o CPF do paciente')}`}
              className="hover-input"
            />
          </InputMask>
        </FormItem>
        <FormItem>
          <p>Status</p>
          <Select
            id="select_status"
            value={status}
            name="status"
            onChange={e => setStatus(e.target.value)}
            placeholder="Selecione"
          >
            <Options value="DEFAULT">Selecione o status</Options>
            <Options value="ONLINE">Online</Options>
            <Options value="OFFLINE">Offline</Options>
          </Select>
        </FormItem>
      </ContainerForm>
      <ContainerButtons>
        <Button
          id="btn_reset"
          onClick={() => resetFilter()}
          variant="secundary"
          size="medium"
          rounded
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          onClick={() => handleFilter()}
          variant="primary"
          size="medium"
          autoFocus
          rounded
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterPatient;
